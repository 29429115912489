.delete {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
}

@media (max-width: 80em) {
  .delete {
    display: grid;
    grid-template-columns: 33% 33% 33%;
  }
}

.card {
  box-shadow: 3px 3px 3px rgb(0,0,0,0.3);
  width: 18rem;
  margin: 10px;
}
.line {
  width: min(90% 50rem);
  display: grid;
  grid-template-columns: 80% 20%;
  padding: 3px;
}
.score {
  background: black;
  color: white;
  text-align: center;
}
p {
  background: black;
  color: white;
  text-align: right;
  padding: 2px;
  margin: 2px;
  padding-right: 5px;
}

